<template>
  <el-dialog title="选择地块" :visible="dialogVisible" width="60%" @close="closeMe" destroy-on-close :close-on-click-modal="false">
    <el-row style="margin-bottom: 12px">
      <el-col :span="12" class="flex-row">
        <el-input v-model="searchParams.name" style="width: 60%" placeholder="请输入地块名称" clearable></el-input>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-table
      v-if="dialogVisible"
      ref="multipleTable"
      :data="tableData"
      border
      style="width: 100%"
      height="50vh"
      :row-key="getRowKey"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" :reserve-selection="true"> </el-table-column>
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="name" label="地址">
        <template slot-scope="scope">
          {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.county }}{{ scope.row.township }}{{ scope.row.village }}{{ scope.row.address }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="面积">
        <template slot-scope="scope">{{ formatPlantArea(scope.row.area) }}亩 </template>
      </el-table-column>
      <el-table-column prop="ownerName" label="负责人"> </el-table-column>
    </el-table>
    <div>
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <el-row>
      <el-col :span="6" class="flex-center"> 已选择{{ multipleSelection.length }}条 </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>
<script>
import { landListContent, startPlantingWithScheme, getRequest } from '../api/api';
import * as utils from '@/utils/utils.js';
export default {
  props: {
    dialogVisible: false
  },
  components: {},
  computed: {},
  data() {
    return {
      tableData: [],
      searchParams: {
        name: ''
      },
      multipleSelection: [],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      }
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      this.multipleSelection = [];
      this.pageInfo = {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      };
      this.searchParams = {
        name: ''
      };

      if (val) {
        this.getList();
      }
    }
  },
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    onSubmit() {
      if (!this.multipleSelection.length) {
        this.$message('请选择地块');
        return;
      }
      let ids = this.multipleSelection.map((e) => {
        return e.id;
      });
      let formData = new FormData();
      formData.append('schemeId', this.$route.query.id);
      formData.append('landIds', ids);
      startPlantingWithScheme(formData)
        .then((res) => {
          this.$message({
            message: '创建成功',
            type: 'success'
          });
          this.closeMe();
          this.updateList();
        })
        .catch((error) => {});
      console.log('');
    },
    updateList() {
      this.$emit('updateList');
    },
    closeMe() {
      this.$emit('update:dialogVisible', false);
    },
    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getRowKey(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      console.log('@选中的值', val);
      this.multipleSelection = val;
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    getList() {
      getRequest('/api/operate/planting/useScheme/land/page', {
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.flex-center {
  height: 40px;
  line-height: 40px;
}
</style>
