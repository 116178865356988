/**
 * 地图瓦片
 * */
// 谷歌瓦片
let googleMap = "//map1.aikenong.com.cn/maps/vt/lyrs=y&hl=zh-CN&gl=cn&scale=2&src=app&x={x}&y={y}&z={z}";
// 高德瓦片
let gaodeMap = "http://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}";

export {
	googleMap,
	gaodeMap
} ;
