<template>
  <el-dialog
    class="dg"
    :visible="punchVisible"
    top="60px"
    append-to-body
    width="1200px"
    @close="closeMe"
    destroy-on-close
    :close-on-click-modal="false"
  >
    <template v-if="punchDetailData">
      <div slot="title" class="title-wrap">
        <div class="title">{{ punchDetailData.farmWorkConclusionName }}</div>
        <div class="time"><span class="label">上传时间</span>{{ punchDetailData.actionTime | filterDate }}</div>
        <div class="latlng"><span class="label">经度</span>{{ punchDetailData.lat }}°，<span class="label">纬度</span>{{ punchDetailData.lon }}°</div>
        <div class="clock-level" v-if="rateValue"
          ><span class="label">严重等级</span>
          <el-rate v-model="rateValue" :show-text="true" text-color="#999999" disabled :texts="['轻', '较轻', '一般', '严重', '非常严重']"> </el-rate>
        </div>
      </div>
      <div class="content-wrap">
        <div
          :class="[
            'desc',
            punchDetailData.conclusion != 1 && punchDetailData.conclusion != 2 && punchDetailData.conclusion != 20 ? 'exception-color' : ''
          ]"
          v-if="punchDetailData.description"
        >
          备注：{{ punchDetailData.description }}
        </div>
        <div class="carousel-wrap" v-if="punchDetailData.imgList && punchDetailData.imgList.length">
          <el-carousel ref="punchCarusel" indicator-position="none" height="490px" :autoplay="false" arrow="always">
            <el-carousel-item v-for="item in punchDetailData.imgList" :key="item">
              <img class="punch-img" :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
          <div class="img-small-warp">
            <img
              @click="clickSmallImg(index)"
              :src="item"
              :class="[currentIndex == index ? 'active' : '', 'punch-small-img']"
              alt=""
              v-for="(item, index) in punchDetailData.imgList"
              :key="item"
            />
          </div>
        </div>
        <div class="no-clock-info" v-else>
          <img src="@/images/patrol_no_info.png" alt="" />
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    punchVisible: false,
    punchDetailData: null
  },
  watch: {
    punchDetailData: {
      handler(val) {
        this.rateValue = val.severityLevel || 0;
      },
      // immediate: true,
      deep: true
    }
  },
  data() {
    return {
      currentIndex: 0,
      rateValue: 0
    };
  },
  filters: {
    filterDate(val) {
      if (val) {
        return dayjs(val).format('YYYY.MM.DD HH:mm');
      }
      return '-';
    }
  },
  methods: {
    closeMe() {
      this.$emit('update:punchVisible', false);
    },
    clickSmallImg(index) {
      this.currentIndex = index;
      this.$refs.punchCarusel.setActiveItem(index);
    }
  }
};
</script>
<style scoped>
.dg >>> .el-dialog__body {
  border-top: 1px solid #ebedf4;
  padding: 10px 20px 20px;
}
.content-wrap >>> .el-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
<style lang="scss" scoped>
.title-wrap {
  font-size: 16px;
  font-family: SourceHanSansCN-Bold, SourceHanSansCN;
  font-weight: 500;
  color: #2c3542;
  display: flex;
  align-items: center;
  .title {
    display: flex;
    align-items: center;
    &::before {
      display: inline-block;
      content: '';
      width: 3px;
      height: 14px;
      background: #02b98f;
      margin-right: 4px;
    }
  }
  .time {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-left: 20px;
    color: #999999;
    margin-right: 20px;
  }
  .latlng {
    color: #999999;
    font-size: 14px;
    margin-right: 20px;
  }
  .clock-level {
    display: flex;
    font-size: 14px;
    color: #999999;
  }
  .label {
    margin-right: 6px;
  }
}
.content-wrap {
  .desc {
    height: 22px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    &.exception-color {
      color: #fa8f00;
    }
  }
  .carousel-wrap {
    width: 100%;
    height: 625px;
    background: #ebedf4;
    border-radius: 8px;
    margin-top: 15px;
  }
  .no-clock-info {
    width: 100%;
    height: 400px;
    text-align: center;
    img {
      height: 100%;
    }
  }
  .el-carousel {
    width: 730px;
    margin: 0 auto;
    padding: 20px 0;
    .punch-img {
      width: 100%;
      height: 490px;
      object-fit: cover;
    }
  }
  .img-small-warp {
    display: flex;
    align-items: center;
    justify-content: center;
    .punch-small-img {
      width: 100px;
      height: 75px;
      opacity: 0.4;
      border-radius: 4px;
      margin-right: 10px;
      object-fit: cover;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        opacity: 1;
      }
    }
  }
}
</style>
