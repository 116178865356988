<template>
  <el-dialog :title="dialogTitle" :visible="dialogVisible" width="40%" @close="onClose" destroy-on-close :close-on-click-modal="false">
    <el-form ref="form" label-width="120px">
      <el-form-item label="回答:">
        <el-input type="textarea" autosize v-model="content"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">{{ isEdit ? '修改' : '立即创建' }}</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { answerDetail, answer, answerEdit } from '../api/api';
export default {
  props: {
    dialogVisible: false,
    isEdit: false,
    isDisable: false,
    currentId: ''
  },
  components: {},
  computed: {
    dialogTitle() {
      if (this.isEdit) {
        return '修改答案';
      }
      return '录入答案';
    }
  },
  data() {
    return {
      content: '',
      id: ''
    };
  },
  created() {},
  destroyed() {},
  mounted() {},
  watch: {
    dialogVisible(val) {
      if (val) {
        this.content = '';
        if (this.isEdit) {
          this.getDetail();
        }
      }
    }
  },
  methods: {
    getDetail() {
      answerDetail({ questionId: this.currentId }).then(e => {
        this.id = e.id;
        this.content = e.content;
      }).catch(error => {

      });
    },
    onClose() {
      this.$emit('update:dialogVisible', false);
    },
    onSubmit() {
      if (!this.content) {
        this.$message('请输入答案');
        return;
      }
      if (this.isEdit) {
        answerEdit({ id: this.id, questionId: this.currentId, content: this.content }).then(e => {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('updateList');
        }).catch(error => {

      });
      } else {
        answer({ questionId: this.currentId, content: this.content }).then(e => {
          this.$message({
            message: '回答成功',
            type: 'success'
          });
          this.$emit('update:dialogVisible', false);
          this.$emit('updateList');
        }).catch(error => {

      });
      }
    }
  }
};
</script>
<style scoped lang="scss"></style>
